import React, {
  CSSProperties,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { Button, ButtonProps, Popup, Radio } from "semantic-ui-react";

import moment from "moment";

import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import { useIntl } from "react-intl";

const DATE_FORMAT = "YYYY-MM-DD";

const HOLD_OFF: Record<string, string> = {
  HOLD_NOW: "HOLD NOW",
  OFF_NOW: "OFF NOW",
};

type ActionMenuColumnProps = {
  id?: string;
  setProp?: (key: any, value: any, callback?: () => any) => any;
  actions: string[];
  allowPropagation?: boolean;
  buttonColors?: Record<string, string>;
  buttonLabels?: Record<string, string>;
  buttonSize?: ButtonProps["size"];
  cardId: string;
  date?: moment.Moment;
  fluidButtons?: boolean;
  isButtonDisabled?: ((action: string) => boolean) | boolean;
  isMainButtonDisabled?: boolean;
  item: any;
  keepOpenActions?: string[];
  popupStyle?: CSSProperties;
  timeSelectionLabel?: string;
  buttonLoadCheck?: Record<string, string>;
  getButtonStyle?: (action: string) => CSSProperties;
  getPreActionCheck?: (action: string) => boolean;
  getTimes?: (action: string) => string[];
  onCallback: CallbackHandler;
};

export type CallbackHandler = (action: string, endTime?: string, endDate?: string) => void;

const DEFAULT_BUTTON_COLORS = {
  CANCEL: "red",
  EDIT: "yellow",
  HOLD: "brown",
  OFF: "black",
  RENEW: "teal",
  RESUME: "blue",
};

const ActionMenuColumn = (props: ActionMenuColumnProps) => {
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedAction, setSelectedAction] = useState<string>("");
  const [checkedTime, setCheckedTime] = useState<string>("");
  const [showTimeSelection, setShowTimeSelection] = useState<boolean>(false);

  const buttonColors = useMemo(
    () => ({
      ...DEFAULT_BUTTON_COLORS,
      ...props.buttonColors,
    }),
    [props.buttonColors]
  );

  const isDisabledButton = useMemo(() => {
    const visibleActions = props.actions.filter((action) => {
      if (!Object.keys(buttonColors).includes(action)) {
        return false;
      }

      if (props.getButtonStyle) {
        const style = props.getButtonStyle(action);

        return style.display !== "none";
      }

      return true;
    });

    return visibleActions.length > 0;
  }, [buttonColors, props.actions, props.getButtonStyle]);

  const timeLine = useMemo(() => {
    const current = moment();
    const date = props.date ? moment(props.date) : undefined;

    if (!date) {
      return ""; // or handle the undefined date case as needed
    }

    if (current.isSame(date, "day")) {
      return "present";
    }

    if (current.isBefore(date, "day")) {
      return "future";
    }

    if (current.isAfter(date, "day")) {
      return "past";
    }

    return "";
  }, [props.date]);

  const isMainButtonDisabled = useMemo(() => {
    if (props.isMainButtonDisabled !== undefined) {
      return props.isMainButtonDisabled;
    }

    return !isDisabledButton || timeLine === "past";
  }, [isDisabledButton, props.isMainButtonDisabled, timeLine]);

  const defaultGetTimes = useCallback(
    (action: string) => {
      const stdItems: string[] = props.item?.std_times || [];

      if (timeLine === "present" && ["HOLD", "OFF"].includes(action)) {
        const hm = moment().format("HH:mm");

        return [
          HOLD_OFF[`${action}_NOW`],
          ...stdItems
            .filter((time) => moment(time, "HH:mm").isSameOrAfter(moment(hm, "HH:mm")))
            .map((time) => `${time} น.`),
        ];
      }

      return stdItems.map((time) => `${time} น.`);
    },
    [props.item?.std_times, timeLine]
  );

  const getTimes = props.getTimes || defaultGetTimes;

  useEffect(() => {
    if (
      !(
        selectedAction !== "" &&
        ["ERROR", "SUCCESS"].includes(
          props.buttonLoadCheck?.[`${props.cardId}_${selectedAction}`] || ""
        )
      )
    ) {
      return;
    }

    setIsOpen(false);
    setSelectedAction("");
  }, [props.buttonLoadCheck, props.cardId, selectedAction]);

  const defaultPreActionCheck = useCallback(
    (action: string) => ["HOLD", "OFF"].includes(action) && !!props.item?.std_times?.length,
    [props.item?.std_times]
  );

  const getPreActionCheck = props.getPreActionCheck || defaultPreActionCheck;

  const handleActionClick = (action: string) => (e: SyntheticEvent) => {
    e.stopPropagation();

    if (getPreActionCheck(action)) {
      setSelectedAction(action);
      setCheckedTime("");
      setShowTimeSelection(!showTimeSelection); // Show time selection when actively selecting an action
    } else {

      const date = props.date?.format(DATE_FORMAT);
      handleSave(action, undefined, date);
    }
  };

  const handleSave = (action: string, endTime?: string, endDate?: string) => {
    if (!props.keepOpenActions?.includes(action)) {
      setIsOpen(false);
    }

    setShowTimeSelection(false); // Hide time selection after save

    props.onCallback(action, endTime, endDate);
  };

  const handleSelectTime = () => {
    if (selectedAction && checkedTime) {
      const hm = checkedTime.replaceAll(/ น\.$/g, "");

      if ([HOLD_OFF.HOLD_NOW, HOLD_OFF.OFF_NOW].includes(hm)) {
        const time = moment().format("HH:mm");

        handleSave(selectedAction, time);
      } else {
        const date = props.date?.format(DATE_FORMAT);

        handleSave(selectedAction, hm, timeLine === "future" ? date : undefined);
      }
    }
  };

  const renderButtons = () =>
    props.actions
      .filter((action) => Object.keys(buttonColors).includes(action))
      .map((action: string) => {
        const isDisabled =
          typeof props.isButtonDisabled === "function"
            ? props.isButtonDisabled(action)
            : props.isButtonDisabled ?? false;

        return (
          <ButtonLoadCheck
            id={`ButtonLoadCheck-${action}-` + props.id}
            key={action}
            setProp={props.setProp}
            color={(buttonColors as any)[action]}
            disabled={isDisabled}
            fluid={props.fluidButtons}
            name={action}
            paramKey={`${props.cardId}_${action}`}
            title={props.buttonLabels?.[action] || action}
            buttonLoadCheck={props.buttonLoadCheck?.[`${props.cardId}_${action}`]}
            onClick={handleActionClick(action)}
            style={
              props.getButtonStyle
                ? props.getButtonStyle(action)
                : { margin: "0 0.25rem 0.25rem 0", width: "7em" }
            }
          />
        );
      });

  const renderTimeSelection = () => {
    if (!(showTimeSelection && selectedAction)) {
      return null;
    }

    return (
      <div
        aria-hidden="true"
        style={{ fontWeight: "bold", margin: "0.5rem 0.25rem" }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div>
          [{selectedAction}] {props.timeSelectionLabel || intl.formatMessage({id: "กรุณาเลือก Last dose"})}
        </div>
        {getTimes(selectedAction).map((time: string) => (
          <div key={time}>
            <Radio
              checked={checkedTime === time}
              label={time}
              style={{ margin: "0.5rem 0" }}
              onChange={() => {
                setCheckedTime(time);
              }}
            />
          </div>
        ))}
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button color="green" disabled={!checkedTime} size="mini" onClick={handleSelectTime}>
            OK
          </Button>
        </div>
      </div>
    );
  };

  const handleClick = (e: React.MouseEvent) => {
    if (!props.allowPropagation) {
      e.stopPropagation();
    }
  };

  const handleDoubleClick = (e: React.MouseEvent) => {
    if (!props.allowPropagation) {
      e.stopPropagation();
    }
  };

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Popup
        id={`Popup-` + props.id}
        open={isOpen}
        position="bottom right"
        style={props.popupStyle || { padding: "6px 8px" }}
        on="click"
        onClose={() => {
          setIsOpen(false);
          // Close the popup and hide the time selection.
          setShowTimeSelection(false);
          setCheckedTime("");
          setSelectedAction("");
        }}
        onOpen={() => {
          setIsOpen(true);
        }}
        content={
          <>
            {renderButtons()}
            {renderTimeSelection()}
          </>
        }
        trigger={
          <Button
            id={`Button-` + props.id}
            disabled={isMainButtonDisabled}
            icon="list layout"
            size={props.buttonSize || "mini"}
            onClick={handleClick}
            onDoubleClick={handleDoubleClick}
          />
        }
      />
    </div>
  );
};

export default React.memo(ActionMenuColumn);
