import React, { useCallback, useEffect, useMemo, useState } from "react";

import moment from "moment";

// UX
import CardPlanTransferUX from "./CardPlanTransferUX";

// Common
import ModMedReconcileAlert from "react-lib/apps/common/ModMedReconcileAlert";
import ModConfirm from "react-lib/apps/common/cnmi/ModConfirm";
import SnackMessage from "react-lib/apps/common/SnackMessage";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import TimeComboBox from "react-lib/apps/common/TimeComboBox";

// Interface
import { State, SetProp } from "./sequence/PlanTransfer";

// Utils
import { formatDate } from "react-lib/utils/dateUtils";
import { useIntl } from "react-intl";

// Types
type CardPlanTransferProps = {
  onEvent: (e: any) => any;
  setProp: SetProp;
  // seq
  runSequence: any;
  PlanTransferSequence?: State["PlanTransferSequence"];
  // data
  medReconcileCheck?: any;
  medReconcileIndex?: any;
  // CommonInterface
  django?: any;
  selectedEmr?: Record<string, any>;
  divisionType?: string | null;
  selectedEncounter?: Record<string, any>;
  errorMessage?: Record<string, any>;
  buttonLoadCheck?: Record<string, any>;
  // options
  masterOptions?: any;
};

const BUTTON_ACTIONS = {
  save: "save",
};

const CARD_PLAN_TRANSFER = "CardPlanTransfer";

const ACTION_SAVE = `${CARD_PLAN_TRANSFER}_${BUTTON_ACTIONS.save}`;

const CardPlanTransfer = (props: CardPlanTransferProps) => {
  const intl = useIntl();
  const [openConfirmedDelete, setOpenConfirmedDelete] = useState(false);

  useEffect(() => {
    if (props.selectedEmr) {
      props.runSequence({ sequence: "PlanTransfer", restart: true });
    }

    return () => {
      props.runSequence({ sequence: "PlanTransfer", clear: true });
    };
  }, [props.selectedEmr]);

  // Callback
  const getMsgLabel = useCallback((error: any) => {
    const keys = Object.keys(error);

    const otherError = keys.filter((key) => !["date", "urgency"].includes(key));

    if (otherError.length) {
      return error;
    }

    return Object.entries(error).reduce((result: any[], [key]: any) => {
      const msgLabel = {
        date: "วัน/เวลาที่ย้าย",
        urgency: "ความเร่งด่วน",
      } as any;

      return [...result, msgLabel[key] || key];
    }, []);
  }, []);

  // Memo
  const errorMsgLabel = useMemo(() => {
    const error = props.errorMessage?.[CARD_PLAN_TRANSFER];
    const show = error?.show;

    const errorLabel = getMsgLabel(error?.error || {});

    if (!show) {
      return null;
    }

    if (Array.isArray(errorLabel)) {
      return {
        กรอกข้อมูลที่จำเป็น: errorLabel,
      };
    }

    return errorLabel;
  }, [props.errorMessage]);

  // Cancel (De)
  const handleConfirmCancelPlanTransfer = () => {
    props.runSequence({
      sequence: "PlanTransfer",
      action: "delete",
      card: CARD_PLAN_TRANSFER,
    });

    setOpenConfirmedDelete(false);
  };

  const handleCloseModConfirm = () => {
    setOpenConfirmedDelete(false);
  };

  // Switch Tab
  const handleConfirmSwithTab = () => {
    props.onEvent({
      message: "OpenMedReconcileFromWarning",
      params: {
        medReconcileCheck: props.medReconcileCheck,
        selectedRecordViewIndex: props.medReconcileIndex,
      },
    });

    props.setProp("PlanTransferSequence.openRedirect", false);
  };

  const handleCloseModSwithTab = () => {
    props.setProp("PlanTransferSequence.openRedirect", false);
  };

  const handleSave = () => {
    props.runSequence({
      sequence: "PlanTransfer",
      action: "save",
      card: CARD_PLAN_TRANSFER,
    });
  };

  return (
    <div>
      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(`errorMessage.${CARD_PLAN_TRANSFER}.show`, false);
        }}
        error={errorMsgLabel}
        success={null}
        languageUX={props.languageUX}
      />

      <ModConfirm
        openModal={props.PlanTransferSequence?.openRedirect}
        titleName={intl.formatMessage({ id: "แจ้งเตือนทบทวนการใช้ยา" })}
        textContent={intl.formatMessage({ id: "ต้องการบันทึกข้อมูลทบทวนการใช้ยา Medication Reconciliation IPD Transfer ใช่หรือไม่ ?" })}        size="mini"
        titleColor="blue"
        denyButtonColor="red"
        denyButtonText={intl.formatMessage({ id: "ไม่" })}
        approveButtonColor="green"
        approveButtonText={intl.formatMessage({ id: "ใช่" })}
        onApprove={handleConfirmSwithTab}
        onDeny={handleCloseModSwithTab}
        onCloseWithDimmerClick={handleCloseModSwithTab}
      />

      <ModConfirm
        openModal={openConfirmedDelete}
        titleName={intl.formatMessage({ id: "ต้องการยกเลิกใช่หรือไม่" })}
        textContent={`ต้องการยกเลิก plan transfer ${props.PlanTransferSequence?.date} ใช่หรือไม่`}
        size="mini"
        titleColor="orange"
        denyButtonColor="blue"
        denyButtonText={intl.formatMessage({ id: "ไม่" })}
        approveButtonColor="orange"
        approveButtonText={intl.formatMessage({ id: "ใช่" })}
        onApprove={handleConfirmCancelPlanTransfer}
        onDeny={handleCloseModConfirm}
        onCloseWithDimmerClick={handleCloseModConfirm}
      />

      <ModMedReconcileAlert
        onEvent={props.onEvent}
        setProp={props.setProp}
        divisionType={props.divisionType}
        django={props.django}
        medReconcileCheck={props.medReconcileCheck}
        medReconcileIndex={props.medReconcileIndex}
        open={props.medReconcileCheck?.open_card_med_reconciliation}
        selectedEncounter={props.selectedEncounter}
        languageUX={props.languageUX}
      />

      <CardPlanTransferUX
        // Date
        onTransferDateChange={(date: any) => {
          console.log("PlanTransferSequence date: ", date);
          props.setProp("PlanTransferSequence.date", date || null);
        }}
        minTransferDate={formatDate(moment())}
        dateTransfer={props.PlanTransferSequence?.date || null}
        showRequiredField={props.errorMessage?.[CARD_PLAN_TRANSFER]?.error}
        // Dropdown
        urgencyOptions={props.masterOptions?.transferUrgency || []}
        urgency={props.PlanTransferSequence?.urgency}
        wardTypeOptions={props.masterOptions?.wardType || []}
        wardType={props.PlanTransferSequence?.wardType}
        roomTypeOptions={props.masterOptions?.roomType || []}
        roomType={props.PlanTransferSequence?.roomType}
        divisionOptions={props.masterOptions?.division || []}
        division={props.PlanTransferSequence?.division}
        onCancel={() => {
          setOpenConfirmedDelete(true);
        }}
        hideCancel={!props.PlanTransferSequence?.id}
        // callback
        onUrgencyChange={(_e: any, { value }: any) => {
          props.setProp("PlanTransferSequence.urgency", value);
        }}
        onWardTypeChange={(_e: any, { value }: any) => {
          props.setProp("PlanTransferSequence.wardType", value);
        }}
        onRoomTypeChange={(_e: any, { value }: any) => {
          props.setProp("PlanTransferSequence.roomType", value);
        }}
        onDivisionChange={(_e: any, { value }: any) => {
          props.setProp("PlanTransferSequence.division", value);
        }}
        buttonSave={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleSave}
            // data
            paramKey={ACTION_SAVE}
            buttonLoadCheck={props.buttonLoadCheck?.[ACTION_SAVE]}
            // config
            color={"green"}
            name="SEARCH"
            size="medium"
            title={props.PlanTransferSequence?.id ? "แก้ไข" : "บันทึก"}
          />
        }
        timeCombobox={
          <TimeComboBox
            defaultValue={props.PlanTransferSequence?.time || null}
            onTextChange={(time) => props.setProp("PlanTransferSequence.time", time)}
            style={{ width: "100%" }}
          />
        }
        languageUX={props.languageUX}
      />
    </div>
  );
};

CardPlanTransfer.displayName = "CardPlanTransfer";

export default React.memo(CardPlanTransfer);
