import React, { CSSProperties, SyntheticEvent, useCallback, useEffect, useState } from "react";

import { Dropdown, DropdownProps, Form } from "semantic-ui-react";

import ModAuthen from "react-lib/apps/common/ModAuthen";

// Types
type ModCancelExpenseProps = {
  error?: any;
  open: boolean;
  title?: string;
  buttonLoadCheck?: Record<string, any> | null;
  divisionOptions?: any[];
  options?: any[];
  onApprove: (data: any) => void;
  onClose: () => void;
  isRequire?: boolean;
};

type Styles = Record<
  "customGridButton" | "customGridGroup" | "divisionDropdown" | "dropdown" | "inline",
  CSSProperties
>;

// Constants
const styles: Styles = {
  customGridButton: { flex: 1 },
  customGridGroup: { padding: "0 2rem" },
  divisionDropdown: { flex: 4, marginLeft: "0.66rem" },
  dropdown: { flex: 4, marginLeft: "1.55rem" },
  inline: { alignItems: "center", display: "flex" },
};

const BUTTON_ACTIONS = {
  cancel: "CANCEL",
};

const MOD_CANCEL_EXPENSE = "ModCancelExpense";

const ACTION_CANCEL = `${MOD_CANCEL_EXPENSE}_${BUTTON_ACTIONS.cancel}`;

const ModCancelExpense = (props: ModCancelExpenseProps) => {
  const [errorMessage, setErrorMessage] = useState<any>(null);
  const [options, setOptions] = useState<any[]>([]);
  const [remark, setRemark] = useState<any>("");
  const [division, setDivision] = useState<any>("");

  useEffect(() => {
    if (!props.open) {
      return;
    }

    setErrorMessage(null);
    setRemark("");
    setDivision("");
  }, [props.open]);

  useEffect(() => {
    setOptions(props.options || []);
  }, [props.options]);

  const handleAddOptions = useCallback(
    (e: SyntheticEvent, v: any) => {
      setOptions([...options, { key: v.value, text: v.value, value: v.value }]);
    },
    [options]
  );

  const handleChange = useCallback((e: SyntheticEvent, data: DropdownProps) => {
    setRemark(data.value);
  }, []);

  const handleChangeDivision = useCallback((e: SyntheticEvent, data: DropdownProps) => {
    setDivision(data.value);
  }, []);

  const handleApprove = useCallback(
    (data: any) => {
      const error = {
        ...(!remark && { remark: "กรุณาระบุ เหตุผลยกเลิก" }),
        ...(!division && props.divisionOptions && { division: "กรุณาระบุ หน่วยงานที่ร้องขอ" }),
      };

      if (Object.keys(error).length > 0) {
        setErrorMessage(error);

        return;
      }

      props.onApprove({ ...data, btnAction: ACTION_CANCEL, division, remark });
    },
    [division, props.onApprove, remark]
  );

  const handleClose = useCallback(() => {
    setErrorMessage(null);
    setRemark("");
    setDivision("");

    props.onClose();
  }, []);

  return (
    <ModAuthen
      approveButtonColor="red"
      approveButtonLoading={props.buttonLoadCheck?.[ACTION_CANCEL]}
      approveButtonText="ยืนยัน"
      basic={false}
      customGridButton={styles.customGridButton}
      customGridGroup={styles.customGridGroup}
      denyButtonColor="gray"
      denyButtonText="ปิดหน้าต่าง"
      error={props.error}
      open={props.open}
      titleColor={"red"}
      titleName={props.title ?? "ยืนยันการยกเลิกรายการค่าใช้จ่าย"}
      inline
      onApprove={handleApprove}
      onCloseWithDimmerClick={handleClose}
      onDeny={handleClose}
      isRequire={props.isRequire}
    >
      {props.divisionOptions && (
        <Form.Field error={!!errorMessage?.division} style={styles.inline}>
          <div
            style={{
              display: "flex",
              fontSize: "13px",
              fontWeight: "bold",
              flex: 2,
              minWidth: "max-content",
            }}
          >
            <label>หน่วยงานที่ร้องขอ</label>
            <div style={{ color: "red" }}>*</div>
          </div>
          <Dropdown
            style={styles.divisionDropdown}
            value={division}
            clearable
            search
            selection
            options={props.divisionOptions}
            onChange={handleChangeDivision}
          />
        </Form.Field>
      )}
      <Form.Field error={!!errorMessage?.remark} style={styles.inline}>
        <div
          style={{
            display: "flex",
            fontSize: "13px",
            fontWeight: "bold",
            flex: 2,
            minWidth: "max-content",
          }}
        >
          <label>เหตุผลยกเลิก</label>
          <div style={{ color: "red" }}>*</div>
        </div>
        <Dropdown
          style={styles.dropdown}
          value={remark}
          allowAdditions
          clearable
          search
          selection
          options={options}
          onAddItem={handleAddOptions}
          onChange={handleChange}
        />
      </Form.Field>
    </ModAuthen>
  );
};

ModCancelExpense.displayname = "ModCancelExpense";

export default React.memo(ModCancelExpense);
