import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  DateTextBox
} from 'react-lib/apps/common'
import {
  Dropdown,
  Button,
  FormGroup,
  FormField
} from 'semantic-ui-react'

const CardPlanTransferUX = (props: any) => {
    return(
      <div
        id="CardPlanTransferUX-div-0"
        style={{margin:"10px"}}>
        <div
          id="CardPlanTransferUX-div-1"
          style={{fontSize: "20px", fontWeight: "bold"}}>
          Plan Transfer
        </div>
        <hr
          id="CardPlanTransferUX-hr-6">
        </hr>
        <div
          className="ui form"
          id="CardPlanTransferUX-div-2"
          style={{padding:"10px"}}>

          <FormGroup
            id="CardPlanTransferUX-FormGroup-40">
            <FormField
              className={props.showRequiredField?.date?"error":""}
              id="CardPlanTransferUX-FormField-46"
              inline={true}
              width={5}>
              <div
                id="CardPlanTransferUX-div-12"
                style={{display:"flex"}}>

                <div
                  id="CardPlanTransferUX-div-22"
                  style={{display:"flex", alignSelf:"center",minWidth: "120px"}}>
                  วัน/เวลาที่ย้าย
                </div>
                <div
                  id="CardPlanTransferUX-div-52"
                  style={{width:"100%"}}>

                  <DateTextBox
                    id="CardPlanTransferUX-DateTextBox-33"
                    minDate={props.minTransferDate}
                    onChange={props.onTransferDateChange}
                    style={{width:"100%"}}
                    value={props.dateTransfer}>
                  </DateTextBox>
                </div>
              </div>
            </FormField>
            <FormField
              id="CardPlanTransferUX-FormField-62"
              width={3}>
              <div
                id="CardPlanTransferUX-div-63"
                style={{width: "100%"}}>
                {props.timeCombobox}
              </div>
            </FormField>
            <FormField
              id="CardPlanTransferUX-FormField-64"
              width={1}>
            </FormField>
            <FormField
              className={props.showRequiredField?.urgency?"error":""}
              id="CardPlanTransferUX-FormField-47"
              inline={true}
              width={7}>
              <div
                id="CardPlanTransferUX-div-13"
                style={{display:"flex",gap:"10px"}}>

                <div
                  id="CardPlanTransferUX-div-23"
                  style={{display:"flex", alignSelf:"center",minWidth: "100px"}}>
                  ความเร่งด่วน
                </div>
                <div
                  id="CardPlanTransferUX-div-58"
                  style={{width:"85%"}}>

                  <Dropdown
                    fluid={true}
                    id="CardPlanTransferUX-Dropdown-35"
                    onChange={props.onUrgencyChange}
                    options={props.urgencyOptions}
                    selection={true}
                    style={{width:"85%"}}
                    value={props.urgency}>
                  </Dropdown>
                </div>
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            id="CardPlanTransferUX-FormGroup-41">
            <FormField
              id="CardPlanTransferUX-FormField-48"
              inline={true}
              style={{}}
              width={5}>
              <div
                id="CardPlanTransferUX-div-54"
                style={{display:"flex",width:"100%"}}>

                <div
                  id="CardPlanTransferUX-div-24"
                  style={{display:"flex", alignSelf:"center",minWidth: "120px"}}>
                  ประเภทหอผู้ป่วย
                </div>
                <Dropdown
                  fluid={true}
                  id="CardPlanTransferUX-Dropdown-36"
                  onChange={props.onWardTypeChange}
                  options={props.wardTypeOptions}
                  selection={true}
                  style={{width:"100%"}}
                  value={props.wardType}>
                </Dropdown>
              </div>
            </FormField>
            <FormField
              id="CardPlanTransferUX-FormField-65"
              width={4}>
            </FormField>
            <FormField
              id="CardPlanTransferUX-FormField-49"
              inline={true}
              width={7}>
              <div
                id="CardPlanTransferUX-div-15"
                style={{display:"flex",gap:"10px"}}>

                <div
                  id="CardPlanTransferUX-div-25"
                  style={{display:"flex", alignSelf:"center",minWidth: "100px"}}>
                  ประเภทห้อง
                </div>
                <div
                  id="CardPlanTransferUX-div-59"
                  style={{width:"85%"}}>

                  <Dropdown
                    fluid={true}
                    id="CardPlanTransferUX-Dropdown-37"
                    onChange={props.onRoomTypeChange}
                    options={props.roomTypeOptions}
                    selection={true}
                    style={{width:"85%"}}
                    value={props.roomType}>
                  </Dropdown>
                </div>
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            id="CardPlanTransferUX-FormGroup-42">
            <FormField
              id="CardPlanTransferUX-FormField-50"
              inline={true}
              width={5}>
              <div
                id="CardPlanTransferUX-div-55"
                style={{display:"flex",width:"100%"}}>

                <div
                  id="CardPlanTransferUX-div-26"
                  style={{display:"flex", alignSelf:"center",minWidth: "120px"}}>
                  แผนก
                </div>
                <Dropdown
                  fluid={true}
                  id="CardPlanTransferUX-Dropdown-38"
                  onChange={props.onDivisionChange}
                  options={props.divisionOptions}
                  selection={true}
                  style={{width:"100%"}}
                  value={props.division}>
                </Dropdown>
              </div>
            </FormField>
            <FormField
              id="CardPlanTransferUX-FormField-66"
              width={4}>
            </FormField>
            <FormField
              id="CardPlanTransferUX-FormField-51"
              inline={true}
              width={7}>
              <div
                id="CardPlanTransferUX-div-17"
                style={{display:"flex",gap:"10px"}}>

                <div
                  id="CardPlanTransferUX-div-60">
                  {props.buttonSave}
                </div>
                <Button
                  color="red"
                  id="CardPlanTransferUX-Button-39"
                  onClick={props.onCancel}
                  style={{...(props.hideCancel && {display: "none"})}}>
                  ยกเลิก
                </Button>
              </div>
            </FormField>
          </FormGroup>
        </div>
      </div>
    )
}


export default CardPlanTransferUX

export const screenPropsDefault = {}

/* Date Time : Sun Feb 16 2025 22:13:33 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "id": {
          "type": "value",
          "value": "CardPlanTransferUX-div-0"
        },
        "style": {
          "type": "code",
          "value": "{margin:\"10px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "Plan Transfer"
        },
        "id": {
          "type": "value",
          "value": "CardPlanTransferUX-div-1"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"20px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "id": {
          "type": "value",
          "value": "CardPlanTransferUX-div-2"
        },
        "style": {
          "type": "code",
          "value": "{padding:\"10px\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "hr",
      "parent": 0,
      "props": {
        "id": {
          "type": "value",
          "value": "CardPlanTransferUX-hr-6"
        }
      },
      "seq": 2,
      "void": true
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 46,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardPlanTransferUX-div-12"
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\"}"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "div",
      "parent": 47,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardPlanTransferUX-div-13"
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",gap:\"10px\"}"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "div",
      "parent": 49,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardPlanTransferUX-div-15"
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",gap:\"10px\"}"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "div",
      "parent": 51,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardPlanTransferUX-div-17"
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",gap:\"10px\"}"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "div",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": "วัน/เวลาที่ย้าย"
        },
        "id": {
          "type": "value",
          "value": "CardPlanTransferUX-div-22"
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\", alignSelf:\"center\",minWidth: \"120px\"}"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "div",
      "parent": 13,
      "props": {
        "children": {
          "type": "value",
          "value": "ความเร่งด่วน"
        },
        "id": {
          "type": "value",
          "value": "CardPlanTransferUX-div-23"
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\", alignSelf:\"center\",minWidth: \"100px\"}"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "div",
      "parent": 54,
      "props": {
        "children": {
          "type": "value",
          "value": "ประเภทหอผู้ป่วย"
        },
        "id": {
          "type": "value",
          "value": "CardPlanTransferUX-div-24"
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\", alignSelf:\"center\",minWidth: \"120px\"}"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": null,
      "id": 25,
      "name": "div",
      "parent": 15,
      "props": {
        "children": {
          "type": "value",
          "value": "ประเภทห้อง"
        },
        "id": {
          "type": "value",
          "value": "CardPlanTransferUX-div-25"
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\", alignSelf:\"center\",minWidth: \"100px\"}"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": null,
      "id": 26,
      "name": "div",
      "parent": 55,
      "props": {
        "children": {
          "type": "value",
          "value": "แผนก"
        },
        "id": {
          "type": "value",
          "value": "CardPlanTransferUX-div-26"
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\", alignSelf:\"center\",minWidth: \"120px\"}"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 33,
      "name": "DateTextBox",
      "parent": 52,
      "props": {
        "id": {
          "type": "value",
          "value": "CardPlanTransferUX-DateTextBox-33"
        },
        "minDate": {
          "type": "code",
          "value": "props.minTransferDate"
        },
        "onChange": {
          "type": "code",
          "value": "props.onTransferDateChange"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.dateTransfer"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 35,
      "name": "Dropdown",
      "parent": 58,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardPlanTransferUX-Dropdown-35"
        },
        "onChange": {
          "type": "code",
          "value": "props.onUrgencyChange"
        },
        "options": {
          "type": "code",
          "value": "props.urgencyOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width:\"85%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.urgency"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 36,
      "name": "Dropdown",
      "parent": 54,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardPlanTransferUX-Dropdown-36"
        },
        "onChange": {
          "type": "code",
          "value": "props.onWardTypeChange"
        },
        "options": {
          "type": "code",
          "value": "props.wardTypeOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.wardType"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 37,
      "name": "Dropdown",
      "parent": 59,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardPlanTransferUX-Dropdown-37"
        },
        "onChange": {
          "type": "code",
          "value": "props.onRoomTypeChange"
        },
        "options": {
          "type": "code",
          "value": "props.roomTypeOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width:\"85%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.roomType"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 38,
      "name": "Dropdown",
      "parent": 55,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardPlanTransferUX-Dropdown-38"
        },
        "onChange": {
          "type": "code",
          "value": "props.onDivisionChange"
        },
        "options": {
          "type": "code",
          "value": "props.divisionOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.division"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 39,
      "name": "Button",
      "parent": 17,
      "props": {
        "children": {
          "type": "value",
          "value": "ยกเลิก"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "id": {
          "type": "value",
          "value": "CardPlanTransferUX-Button-39"
        },
        "onClick": {
          "type": "code",
          "value": "props.onCancel"
        },
        "style": {
          "type": "code",
          "value": "{...(props.hideCancel && {display: \"none\"})}"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 40,
      "name": "FormGroup",
      "parent": 2,
      "props": {
        "id": {
          "type": "value",
          "value": "CardPlanTransferUX-FormGroup-40"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 41,
      "name": "FormGroup",
      "parent": 2,
      "props": {
        "id": {
          "type": "value",
          "value": "CardPlanTransferUX-FormGroup-41"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 42,
      "name": "FormGroup",
      "parent": 2,
      "props": {
        "id": {
          "type": "value",
          "value": "CardPlanTransferUX-FormGroup-42"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 46,
      "name": "FormField",
      "parent": 40,
      "props": {
        "className": {
          "type": "code",
          "value": "props.showRequiredField?.date?\"error\":\"\""
        },
        "id": {
          "type": "value",
          "value": "CardPlanTransferUX-FormField-46"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 47,
      "name": "FormField",
      "parent": 40,
      "props": {
        "className": {
          "type": "code",
          "value": "props.showRequiredField?.urgency?\"error\":\"\""
        },
        "id": {
          "type": "value",
          "value": "CardPlanTransferUX-FormField-47"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 48,
      "name": "FormField",
      "parent": 41,
      "props": {
        "id": {
          "type": "value",
          "value": "CardPlanTransferUX-FormField-48"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{}"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 49,
      "name": "FormField",
      "parent": 41,
      "props": {
        "id": {
          "type": "value",
          "value": "CardPlanTransferUX-FormField-49"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 50,
      "name": "FormField",
      "parent": 42,
      "props": {
        "id": {
          "type": "value",
          "value": "CardPlanTransferUX-FormField-50"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 51,
      "name": "FormField",
      "parent": 42,
      "props": {
        "id": {
          "type": "value",
          "value": "CardPlanTransferUX-FormField-51"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": null,
      "id": 52,
      "name": "div",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardPlanTransferUX-div-52"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": null,
      "id": 54,
      "name": "div",
      "parent": 48,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardPlanTransferUX-div-54"
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",width:\"100%\"}"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": null,
      "id": 55,
      "name": "div",
      "parent": 50,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardPlanTransferUX-div-55"
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",width:\"100%\"}"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": null,
      "id": 58,
      "name": "div",
      "parent": 13,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "code",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardPlanTransferUX-div-58"
        },
        "style": {
          "type": "code",
          "value": "{width:\"85%\"}"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": null,
      "id": 59,
      "name": "div",
      "parent": 15,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardPlanTransferUX-div-59"
        },
        "style": {
          "type": "code",
          "value": "{width:\"85%\"}"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": null,
      "id": 60,
      "name": "div",
      "parent": 17,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSave"
        },
        "id": {
          "type": "value",
          "value": "CardPlanTransferUX-div-60"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 62,
      "name": "FormField",
      "parent": 40,
      "props": {
        "id": {
          "type": "value",
          "value": "CardPlanTransferUX-FormField-62"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": null,
      "id": 63,
      "name": "div",
      "parent": 62,
      "props": {
        "children": {
          "type": "code",
          "value": "props.timeCombobox"
        },
        "id": {
          "type": "value",
          "value": "CardPlanTransferUX-div-63"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 64,
      "name": "FormField",
      "parent": 40,
      "props": {
        "id": {
          "type": "value",
          "value": "CardPlanTransferUX-FormField-64"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 65,
      "name": "FormField",
      "parent": 41,
      "props": {
        "id": {
          "type": "value",
          "value": "CardPlanTransferUX-FormField-65"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 66,
      "name": "FormField",
      "parent": 42,
      "props": {
        "id": {
          "type": "value",
          "value": "CardPlanTransferUX-FormField-66"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 51,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardPlanTransferUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
